import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getUsersCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/users/count", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getEnableUsersCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/getEnableUsersCount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getAllUser = (current, pageSize) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/users?page=" + current + "&size=" + pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const getEnableUsers = (current, pageSize) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/getEnableUsers?page=" + current + "&size=" + pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const getRoles = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/roles", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getPermissions = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/permissions", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addUser = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/adduser", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addRole = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/roles", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const editUser = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/edituser", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const editRole = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/roles", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const changePassword = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/users/password", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getUsersByRoles = (value, projectId) => {
  return axios.get(`${APP_CONTEXT_URL}/v1/usersByRoles/${value}?projectId=${projectId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const sendResetCode = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/auth/sendResetCode", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const sendResetPassword = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/auth/resetPassword", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const updateProjectCapacity = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/users/updateProjectCapacity", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};