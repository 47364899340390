import { PlusOutlined, TableOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Steps,
  Upload,
  InputNumber,
  Space,
} from "antd";
import "antd/dist/antd.min.css";
import moment from "moment";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Button as PrimeBtn } from "primereact/button";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAllAgencies } from "../../../apis/AgencyApis";
import imformationImage from "../../../resources/information.png";
import {
  addProject,
  deleteUploadedDrawing,
  drawingUploadApi,
  updateDropboxLink
} from "../../../apis/ProjectApis";
import AddAgencyDialog from "../../agency/dialog/addagencydialog";
import "./style.scss";
import { Dialog } from "primereact/dialog";
import TextArea from "antd/lib/input/TextArea";
import { getAllCompanies } from "../../../apis/CompanyApis";
import { useSelector } from "react-redux";
import { USER_TYPE_OWNER, WONING_MEDIA_ID } from "../../../utils/constants";
import ApiCalendar from 'react-google-calendar-api';
import { constant } from "lodash";
import { conditionToShowCalendar
} from "../../../utils/constants";
const NewProject = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [stepCurrentState, setStepCurrentState] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [project, setProject] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState(null);
  const [informationDialog, setInformationDialog] = useState(false);
  const [visibleAgencyDialog, setVisibleAgencyDialog] = useState(false);
  const [product, setProduct] = useState(false);
  const [isfurnished, setIsFurnished] = useState(false);
  const [dropboxPictureLink, setDropboxPictureLink] = useState("");
  const [e57dropboxLink, setE57DropboxLink] = useState("")
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [agencyOptions, setAgencyOptions] = useState([]);

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [apiCalendar, setApiCalendar] = useState(null);
  const [eventData, setEventData] = useState(null);

  const [form] = Form.useForm();
  const toast = useRef(null);
  const history = useHistory();
  const { Step } = Steps;
  const { Option } = Select;

  const config = {
    clientId: "202214466104-6td84c6m5p3hrb1curh54bbn4vfc0eei.apps.googleusercontent.com",
    apiKey: "AIzaSyD-RKQA0XgmHISKBq7y4US99v1ZwljGINU",
    scope: "https://www.googleapis.com/auth/calendar.readonly",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  
    
  };

  const dropdownStyles = {
    zIndex: 10000,
  };

  useEffect(() => {
    fetchCompanies();
    form.setFieldsValue({ndd: "No"})
  }, [])

  useEffect(() => {
    let api = new ApiCalendar(config);
    setApiCalendar(api);
    api.onLoadCallback = () => {setIsSignedIn(api.sign)};
    api.handleClientLoad();
  }, [])

  useEffect(() => {
    fetchPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  const fetchCompanies = () => {
    setApiLoading(true);
    getAllCompanies()
      .then((res) => {
        if (res.status === 200) {
          let options = (res.data).map((r) => (
            { value: r.id, label: r.name }
          ))
          setCompanyOptions(options);
          setApiLoading(false);
          setCompanies(res.data);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  }

  const loadAllAgencies = () => {
    setApiLoading(true);
    getAllAgencies()
      .then((res) => {
        if (res.status === 200) {
          setAgencies(res.data);
          setAgencyOptions((res.data || []).map((r) => (
            <Option key={r.id} value={r.id}>
              {r.name}
            </Option>
          )))
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  useEffect(() => {
    loadAllAgencies();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const next = () => {
    switch (stepCurrentState) {
      case 0:
        form.validateFields().then((values) => {
          setApiLoading(true);
          const { agency, adjustment, projectComment, companyId, ...otherValues } = values;
          values.companyId = currentUser.userType === USER_TYPE_OWNER ? currentUser.company.id : values.companyId;
          let comments = [];
          let note = getNoteFromComment(eventData);
          if(note !== ""){
            comments.push({
              comment: note
            })
          }
          if(projectComment != null){
            comments.push({
              comment: projectComment,
            })
          }
          
          const projectValue = {
            agency: { id: agency },
            adjustment: adjustment ? adjustment : "None",
            projectComment: comments,
            company: { id: values.companyId },
            report: values.report,
            projectData: eventData,
            ...otherValues,
          };
          addProject({
            id: project && project.id ? project.id : null,
            ...projectValue,
          })
            .then((res) => {
              if (res.status === 200) {
                setProject(res.data);
                setStepCurrentState(stepCurrentState + 1);
                toast.current.show({
                  severity: "success",
                  summary: "Status",
                  detail: "New Project Added Succesfully",
                  life: 3000,
                });
                setApiLoading(false);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: res.response.data,
                  life: 3000,
                });
                setApiLoading(false);
              }
            })
            .catch((e) => {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: e,
                life: 3000,
              });
              setApiLoading(false);
            });
        });
        break;

      default:
        setStepCurrentState(stepCurrentState + 1);
        setApiLoading(false);
        break;
    }
  };

  const getNoteFromComment = (comment) => {
    if(comment == null || comment == ""){
      return "";
    }
    let startWord = "belangrijke informatie";
    let endWord = "berging";

    // Get the starting and ending indices of the words
    let startIndex = comment.toLowerCase().indexOf(startWord) + startWord.length;
    let endIndex = comment.toLowerCase().indexOf(endWord);

    // Extract the substring between the two words
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      return comment.substring(startIndex, endIndex).trim();      
    }
    return "";
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && current < moment().endOf("day");
    // can not select saturday and sunday
    return moment(current).day() === 0 || moment(current).day() === 6;
  };

  const prev = () => {
    setApiLoading(true);
    setStepCurrentState(stepCurrentState - 1);
    setApiLoading(false);
  };

  const getText = (html) => {
    var divContainer= document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }


  const convertData = (description, date, location, summary) => {
    let calendar = form.getFieldValue("calendar");
    let event = form.getFieldValue("event");
    form.resetFields();
    let dateTime = moment(date);
    setEventData(description);

    
    form.setFieldsValue(
      { 
        calendar: calendar,
        event: event,
        measurementDate: dateTime, 
        furnishedType: "No",
        projectOption: "3D",
        measurementType: "A"
      });

      description = description
      .replaceAll("<br>", '\n')
      .replace(/::/g, ":")
      .replace("Postal code", "postalCode")
      .replace("Postcode", "postalCode")
      .replace("House type", "houseType")
      .replace("Options", "projectOption")
      .replace("Is furnished", "furnishedType")
      .replace("Measurement Type", "measurementType")

      .replace("Straatnaam", "street")
      .replace("Street name", "street")
      .replace("Plaats", "city")
      .replace("Location", "city")
      .replace("Street", "street")
      .replace("Prioriteit (NDD)", "ndd")
      .replace("Company (if applicable)", "company")
      .replace("Huisnummer", "houseNumber")
      .replace("Toevoeging", "additionHouseNumber")
      .replace("House number", "houseNumber")
      .replace("Addition house number", "additionHouseNumber")
      .replace("Home or business space?", "product")
      .replace("Soort", "product")
      .replace("Bedrijf", "agency")
      .replace("Agency", "agency")
      .replace("Geschat aantal vierkante meters ( Estimated number of square meters)", "houseType")
      .replace("Aantal vierkante meters", "houseType")
      .replace("Estimated number of square meters (due to time planning)", "houseType")

      .replaceAll(/\bNee\b/g, "No")
      .replaceAll(/\bJa\b/g, "Yes")
      .replace("Woning (GO Wonen)", "House")
      .replaceAll("Woning", "House")
      .replace("Bedrijfsruimte", "BOG")

    description = getText(description)

    console.log(description)  
    let additionHouseNumber = "";
    let houseNumber = ""   
    let dataArray = description.split('\n').map(line => {
      let [key, value] = line.split(':').map(str => str.trim());
      if(line === "" || !line.includes(":")){
        return;
      }
      switch (key) {
        case "ndd":
          if(value.includes("Yes")){
            value = "Yes";
          }else{
            value = "No";
          }
        return { [key]: value };
        case "product":
          if(value == "House"){
            value = "House";
          }else{
            value = "BOG";
          }
          return { [key]: value };
        break;
        case "agency": {
          const agency = agencies.find(t => t.name === value);
          if (agency) return { "agency": agency.id };
          break;
        }
        case "company": {
          if (currentUser?.userType !== USER_TYPE_OWNER) {
            const company = companies.find(t => t.name === value);
            if (company) {
              return { "companyId": company.id, "report": ""  };
            }
          }
          break;
        }
        case "houseNumber": {
          houseNumber = value;
          break;
        }
        case "additionHouseNumber": {
          additionHouseNumber = value;
          break;
        }
        case "ndd": {
          return { "ndd": value };
        }
        case "houseType": {
          let squareMeter = parseInt(value);
          let houseType = "";
          if (squareMeter <= 100 && squareMeter > 0) {
            houseType = "A";
          } else if (squareMeter > 100 && squareMeter < 200) {
            houseType = "B";
          } else if (squareMeter < 300) {
            houseType = "C";
          } else if (squareMeter < 400) {
            houseType = "D";
          } else if (squareMeter < 500) {
            houseType = "E";
          }
          return { "houseType": houseType };
        }
        default: {
          return { [key]: value };
        }
      }
    });

    if(additionHouseNumber !== "" || houseNumber != ""){
      form.setFieldsValue({"number": [houseNumber, additionHouseNumber].filter(t => t).join("-")})
    }

    for (const e of dataArray) {
      form.setFieldsValue(e);
    }

    extractAddress(location, additionHouseNumber);

    //check ndd from summary to set NDD
    if(summary.toLowerCase().includes("ndd")){
      let ndd = "Yes";
      form.setFieldsValue({
        "ndd": ndd
      })
    }

    //set report default is yes
    form.setFieldsValue({"report": "Yes"});
  }

  const extractAddress = (location, additionHouseNumber) => {
    // Use a regular expression to extract the desired parts
    let regex = /^(.*? \d+), (\d+ [A-Z]+) (.*?),/;
    let match = location?.match(regex);

    if (match) {
      let fullStreet = match[1];
      let postcode = match[2];
      let city = match[3];

      // Extract street and house number separately
      let streetRegex = /^(.*) (\d+)$/;
      let streetMatch = fullStreet.match(streetRegex);

      let street = "";
      let houseNumber = "";
      if (streetMatch) {
        street = streetMatch[1];
        houseNumber = streetMatch[2];
      } else {
        street = fullStreet;
      }
      form.setFieldsValue({
        "number": [houseNumber, additionHouseNumber].filter(t => t).join("-"),
        "street": street,
        "postalCode": postcode,
        "city": city
      })

    } else {
      console.log("Address format is incorrect");
    }
  }

  const orderDetailsForm = () => {
    return (
      <React.Fragment>
        <div
          style={{ display: "flex", marginTop: "30px", marginBottom: "20px" }}
        >
          <Form
            form={form}
            size="middle"
            className="custom-form-design"
            onFinish={next}
            wrapperCol={{ span: 10 }}
            labelCol={{ span: 10 }}
          >
            <Row>
              <Col span={12}>
                {showCalendar ? <Form.Item
                  name="calendar"
                  label="Calendar"
                >
                  <Select
                    onChange={(e) => {
                      form.setFieldsValue({event: ""});
                      getAllEvents(e)
                    }}
                    options = {calendarOptions}
                    style={{ width:  350 }}
                    placeholder="Select">
                  </Select>
                </Form.Item> : ""}
                {
                  showEvent ? <Form.Item
                    name="event"
                    label="Event" >
                    <Select
                    onChange={(e) => {
                      let data = eventOptions.find(t => t.value == e);
                      convertData(data?.description, data?.date, data?.label, data?.summary);
                    }}
                    options = {eventOptions}
                    style={{ width:  350 }}
                    placeholder="Select">
                  </Select>
                  </Form.Item> : ""
                }
               
                <Form.Item
                  name="product"
                  label="Product"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Product is required.",
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      form.setFieldsValue({
                        houseType: null,
                        furnishedType: null,
                      });
                      setProduct(e);
                    }}
                    style={{ width:  350 }}
                    placeholder="Select"
                  >
                    {
                      agency?.format !== "Autocad" || agency == null ?
                        <Option value="BOG">BOG</Option> : ""
                    }
                    <Option value="House">House</Option>
                  </Select>
                </Form.Item>

                {currentUser.userType !== USER_TYPE_OWNER && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Customer is required.",
                      },
                    ]}
                    name="companyId"
                    label="Customer"
                  >
                    <Select
                      style={{ width:  350 }}
                      mode={"single"}
                      dropdownStyle={dropdownStyles}
                      placeholder="Select"
                      options={companyOptions}
                      onChange={(e) => {

                        setAgencyOptions((agencies || []).filter(a => a.company?.id === e).map((r) => (
                          <Option key={r.id} value={r.id}>
                            {r.name}
                          </Option>
                        )))
                      }}
                    >
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  name="report"
                  label="Report"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Report is required.",
                    },
                  ]}
                >
                  <Select style={{ width: 350 }} placeholder="Select">
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item> 
                <Form.Item
                  name="city"
                  label="City"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter City",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="street"
                  label="Street"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Street",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="number"
                  label="Number"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="postalCode"
                  label="Post Code"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Post Code",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="agency"
                      label="Agency"
                      wrapperCol={{ span: 11 }}
                      labelCol={{ span: 12 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select agency from list",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          let findAgency = agencies.find(t => t.id == e);
                          setAgency(findAgency);
                          if (findAgency.format === "Autocad" && product === "BOG") {
                            form.setFieldsValue({ product: "House" });
                          }
                        }}
                      >
                        {agencyOptions}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <PrimeBtn
                      className="p-button-rounded my-auto ml-1"
                      type="button"
                      icon="pi pi-plus"
                      style={{
                        backgroundColor: "#037eea",
                        borderColor: "#037eea",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        document.body.style.overflow = "hidden";
                        setApiLoading(true);
                        setVisibleAgencyDialog(true);
                      }}
                    />
                  </Col>
                </Row>
                <Form.Item name="adjustmentRate" label="Adjusted Price">
                  <InputNumber />
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item label="NDD">
                  <Space>
                    <Form.Item
                      name="ndd"
                      required
                      rules={[
                        {
                          required: true,
                          message: "NDD is required.",
                        },
                      ]}
                    >
                      <Select style={{ width: 150 }} placeholder="Select">
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                    <i
                      value="Yes"
                      style={{
                        marginLeft: "10px",
                        fontSize: "large",
                        color: "blue",
                      }}
                      onClick={() => setInformationDialog(true)}
                      class="pi pi-info-circle"
                    ></i>
                  </Space>
                </Form.Item>

                <Form.Item
                  name="furnishedType"
                  label="Is Furnished"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please specify isFurnished?",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    onChange={(e) => {
                      form.setFieldsValue({ houseType: null });
                      setIsFurnished(e);
                    }}
                    placeholder="Select"
                  >
                    <Option
                      disabled={product === "BOG" ? true : false}
                      value="Yes"
                    >
                      Yes
                    </Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="houseType"
                  label="House Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select House Type",
                    },
                  ]}
                >
                  <Select style={{ width: 150 }} placeholder="Select">
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="C">C</Option>
                    <Option
                      disabled={product === "BOG" ? true : false}
                      value="D"
                    >
                      D
                    </Option>
                    <Option
                      disabled={
                        product === "BOG"
                          ? true
                          : isfurnished === "Yes" && product === "House"
                            ? true
                            : false
                      }
                      value="E"
                    >
                      E
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="projectOption"
                  label="Options"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Project Option",
                    },
                  ]}
                >
                  <Select style={{ width: 150 }} placeholder="Select">
                    <Option value="2D">2D</Option>
                    <Option value="3D">3D</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="measurementType"
                  label="Measurement Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Measurement Type",
                    },
                  ]}
                >
                  <Select style={{ width: 150 }} placeholder="Select">
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="measurementDate"
                  label="Measurement Date"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Measurement Date",
                    },
                  ]}
                >
                  <DatePicker disabledDate={disabledDate} />
                </Form.Item>
                <Form.Item name="projectComment" label="Comment">
                  <Input.TextArea style={{ width: 150, height: 70 }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleRemove = (file) => {
    if (project && project.id) {
      setApiLoading(true);
      const formData = new FormData();
      formData.append("file", file.originFileObj);
      deleteUploadedDrawing(project.id, formData)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Image Deleted successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: "First Add Project",
        life: 3000,
      });
    }
  };

  const getAllEvents = (calendarId) =>{
    setApiLoading(true);  
    // Get current date
    var currentDate = new Date();
    // Set time to start of today
    currentDate.setHours(0, 0, 0, 0);

    // Get date for two days ago
    var twoDaysAgo = new Date(currentDate);
    twoDaysAgo.setDate(currentDate.getDate() - 2);

    // Set time to start of two days ago
    twoDaysAgo.setHours(0, 0, 0, 0);

    // Get date for tomorrow
    var tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);

    // Set time to end of tomorrow
    tomorrow.setHours(23, 59, 59, 999);

    // Convert dates to ISO strings
    var timeMin = twoDaysAgo.toISOString();
    var timeMax = tomorrow.toISOString();
    apiCalendar.listEvents({
      'calendarId': calendarId,
      'showDeleted': false,
      'singleEvents': true,
      'orderBy': 'startTime',
      'timeMin': timeMin,
      'timeMax': timeMax
    }).then(response => {
      const events = response.result.items;
      console.log(JSON.stringify(events));
      let options = (events).filter(e => e.location != null && e.location != "").map((r) => (
        { value: r.id, label: r.location, description: r.description
          , date: r.start.date == undefined ? r.start.dateTime : r.start.date
          , summary: r.summary
        }
      ))
      setEventOptions(options);
      setShowEvent(true);
      setApiLoading(false);  
    }).catch(error => {
      console.error('Get all events error:', error);
    });
  }

  const handleConnectCalendar = (isReconnect) => {
    setApiLoading(true);

    if(isReconnect && isSignedIn){
      apiCalendar.handleSignoutClick();
      setShowEvent(false);
      setShowCalendar(false);
      setEventOptions([]);
      setCalendarOptions([]);
    }else{
      if (!isSignedIn) {
        apiCalendar.handleAuthClick().then(() => {
          setIsSignedIn(true);
          getCalendarList();
        }).catch(error => {
          console.error('Sign-in popup was closed:', error);
        });
      } else {
        // User is already signed in, perform actions
        getCalendarList(true);
      }
    }
    setApiLoading(false);
  }

  const getCalendarList = (isRelogIn) => {
    apiCalendar.listCalendars().then(response => {
      const calendars = response.result.items;
      let options = (calendars).map((r) => (
        { value: r.id, label: r.summary }
      ))
      setCalendarOptions(options);
      setShowCalendar(true)
      setApiLoading(false);    
    }).catch(error => {
      console.error('Get all calendars error:', error);
      if(isRelogIn){
        apiCalendar.handleAuthClick().then(() => {
          setIsSignedIn(true);
          getCalendarList(false);
        }).catch(error => {
          console.error('Sign-in popup was closed:', error);
        });
      }    
    });;
  };

  const beforeDrawingUpload = (file) => {
    if (project && project.id) {
      setApiLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      drawingUploadApi(project.id, formData)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Drawing Uploaded Succesfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: "First Add Project",
        life: 3000,
      });
    }
    return false;
  };

  const handleSubmit = () => {
    setApiLoading(true);
    const projectValues = {
      id: project.id,
      dropboxPictureLink: dropboxPictureLink,
      e57DropboxLink: e57dropboxLink,
    };
    console.log("Project values: " + JSON.stringify(projectValues))

    updateDropboxLink(projectValues).then((res) => {
      console.log("Response from update dropbox api:" + JSON.stringify(res))
      if (res.status === 200 && res.data === true) {
        toast.current.show({
          severity: "success",
          summary: "Status",
          detail: "Update dropbox link successfully",
          life: 3000
        })
        setApiLoading(false);
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Status",
          detail: "Update dropbox link failed!",
          life: 3000
        })
        setApiLoading(false);
      }
    }).catch((e) => {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: e,
        life: 3000,
      });
      setApiLoading(false);
    });
  }

  const uploadDrawing = () => {
    return (
      <React.Fragment>
        <Upload
          accept=".png, .jpg, .jpeg, .pdf, .fml, .doc, .docx, .xls, .xlsx"
          listType="picture-card"
          fileList={fileList}
          multiple
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
          beforeUpload={beforeDrawingUpload}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>

        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <Form form={form} style={{ maxWidth: "430px" }} >
            <Form.Item
              required={false}
              name="dropboxPictureLink" label="Picture url link"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 15 }}
              labelAlign={"left"}
            >
              <TextArea placeholder="Enter link" style={{ minWidth: "200px" }} onChange={(e) => { setDropboxPictureLink(e.target.value) }}>
              </TextArea>
            </Form.Item>
            <Form.Item
              required={false}
              name="e57DropboxLink" label="E57 url link"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 15 }}
              labelAlign={"left"}
            >
              <TextArea placeholder="Enter link" style={{ minWidth: "200px" }} onChange={(e) => { setE57DropboxLink(e.target.value) }}>
              </TextArea>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </React.Fragment>
    );
  };

  const steps = [
    {
      key: 1,
      title: "Project Details",
      content: orderDetailsForm(),
      icon: <UploadOutlined />,
    },
    {
      key: 2,
      title: "Drawing Images",
      content: uploadDrawing(),
      icon: <TableOutlined />,
      disabled: true,
    },
  ];

  const changeVisibleAgencyDialog = (bool) => {
    setSelectedAgency(false);
    setApiLoading(false);
    if (bool === false) {
      loadAllAgencies();
    }
    setVisibleAgencyDialog(bool);
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Spin spinning={apiLoading}>
          <Steps type="navigation" current={stepCurrentState}>
            {steps.map((item) => (
              <Step
                key={item.key}
                title={item.title}
                disabled={item.disabled}
              />
            ))}
          </Steps>
          <div className="steps-content">{steps[stepCurrentState].content}</div>
          <div className="steps-action">
            {stepCurrentState > 0 && (
              <Button
                className="p-button-text"
                type="primary"
                style={{ margin: "0 8px", width: "6em" }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {stepCurrentState === steps.length - 1 && (
              <Button
                type="primary"
                style={{ width: "6em" }}
                className="p-button-text"
                onClick={() => {
                  form.resetFields();
                  history.push("/projectDetail");
                  setStepCurrentState(0);
                  if (dropboxPictureLink && e57dropboxLink) {
                    handleSubmit();
                  }
                }}
              >
                Finish
              </Button>
            )}

            {stepCurrentState < steps.length - 1 && !showCalendar && conditionToShowCalendar(permissions) && (
              <Button
                type="primary"
                style={{ width: "10em", marginRight: "1em" }}
                className="p-button-text"
                onClick={() => handleConnectCalendar(false)}
              >
                Connect Calendar
              </Button>
            )}

        {stepCurrentState < steps.length - 1 && showCalendar && (
              <Button
                type="primary"
                style={{ width: "11em", marginRight: "1em" }}
                className="p-button-text"
                onClick={() => handleConnectCalendar(true)}
              >
                Signout Calendar
              </Button>
            )}

            {stepCurrentState < steps.length - 1 && (
              <Button
                type="primary"
                style={{ width: "6em" }}
                className="p-button-text"
                onClick={() => next()}
              >
                Next
              </Button>
            )}
          </div>
        </Spin>
      </div>
      <AddAgencyDialog
        visible={visibleAgencyDialog}
        setVisible={changeVisibleAgencyDialog}
        agency={selectedAgency ? selectedAgency : null}
      />
      <Dialog
        header="Information"
        style={{ width: "40vw" }}
        visible={informationDialog}
        onHide={() => setInformationDialog(false)}
      >
        <img style={{ width: "35vw" }} src={imformationImage} />
      </Dialog>
    </React.Fragment>
  );
};
export default NewProject;
